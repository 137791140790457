@use "../theme/variables.scss" as *;
.web {
  ion-modal::part(backdrop) {
    --ion-backdrop-opacity: 0.5;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.5);
  }

  ion-modal.information-popup {
    --width: 40%;
  }
  ion-modal.campaigns-preview-modal {
    --height: 85%;
  }
  ion-modal {
    .order-card {
      ion-card-content {
        ion-item {
          --background-hover: var(--home-status-bar);
        }
      }
    }
  }

  .on-board {
    .app-logo {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
  }

  .custom-header-web {
    background-color: var(--web-header);

    ion-toolbar {
      --min-height: 50px;
      --background: var(--web-header);

      .contact-icon-cover {
        margin-right: 15px;

        ion-button {
          .navigation-item {
            color: $white-text;
            display: flex;
            align-items: center;

            span {
              padding-left: 5px;
            }

            svg {
              path {
                stroke: var(--content-white) !important;
              }

              rect {
                stroke: var(--content-white) !important;
              }
            }

            &.active {
              svg {
                path {
                  stroke: var(--ion-color-primary) !important;
                }

                rect {
                  stroke: var(--ion-color-primary) !important;
                }
              }
            }
          }
        }
      }

      .custom-toggle {
        position: absolute;
        right: 120px;
        top: 34px;
      }

      ion-title {
        text-align: left !important;
      }
    }

    .header-outer {
      max-width: 1536px;
      margin: 0 auto;
    }

    .title-cover {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .logo {
        width: 50px;
        margin-right: 10px;
        display: block;

        img {
          width: 50px;
          height: auto;
        }

        .app-icon-main {
          .cls-1 {
            fill: #212121;
          }

          .cls-1,
          .cls-2 {
            stroke-width: 0px;
          }

          .cls-2 {
            fill: #ffa3af;
          }
        }
      }
    }

    .notification-icon-cover {
      .contact-icon {
        svg {
          #Path_5 {
            fill: #fff;
          }
        }
      }

      .notification-icon {
        position: relative;
        left: -4px;

        .badge {
          position: absolute;
          right: -8px;
          top: -3px;
          width: 19px;
          height: 19px;
          border-radius: 50%;
          background-color: #c52632;
          font-size: 9px;
          font-weight: 600;
          color: $white-text;
          line-height: 18px;
          text-align: center;
        }
      }
    }

    .notification-icon {
      #Fill-1 {
        fill: #fff;
      }
    }

    .menu-icon-web {
      svg {
        #Menu-2 {
          fill: #fff;
        }
      }
    }
  }

  .web-content {
    margin-top: 15px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    background: var(--page-bg-grey);
    border-radius: 5px;
    padding: 10px 10px 5px 10px;
    min-height: 90%;

    .cards-area {
      ion-card {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .content-section-outer-level {
      .create-card {
        margin-left: 0;
        margin-right: 0;
        cursor: pointer;
      }

      .create-campaign {
        .create-campaign-form-area {
          padding-left: 0;
          padding-right: 0;

          .fields-section {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

    .sub-header {
      border-radius: 10px;
    }
  }

  .information-block {
    ion-card {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .cards-area-main-outer {
    
    .campaign-card-cover {
      display: grid;
      grid-template-columns: auto auto;
      gap: calc(var(--base-margin) * 2);
      margin: calc(var(--base-margin) * 2);

      .campaign-card {
        margin: 0;
      }
    }
  }

  .web-content {
    &.statistics-page-web,
    &.statistics-page-web {
      .option-selector {
        margin-top: 10px;
      }
    }

    &.store-page-web {

      .option-selector {
        padding: 10px;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;

        ion-button {
          --background-hover: var(--button-hover-primary)
        }
      }

      .connected-websites-section {
        ion-card {
          margin: 0;
          margin-bottom: 15px;
        }
      }

      .web-section-connect {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 10px;
      }

      .single-connected-web-card,
      .connected-websites-section {
        ion-card {
          ion-card-header {
            .connected-block-header {
              .connected-block-caption {
                .brand-caption {
                  .caption-type {
                    max-width: 600px !important;
                  }
                }
              }
            }
          }
        }
      }

      .section-without-integration {
        h4 {
          padding-left: 0;
        }
      }
    }

    &.email-login-page-web,
    &.forgot-password-page-web {
      background: transparent;

      .button-section {
        width: 500px;
        margin-left: auto;
        margin-right: auto;
      }

      .inner-contents {
        width: 500px;
        margin-left: auto;
        margin-right: auto;
      }

      .back-icon {
        app-back-icon {
          cursor: pointer;
        }
      }
    }

    &.email-login-page-web {
      p {
        &.ion-text-center {
          cursor: pointer;
        }
      }
    }

    &.home-page-web {
      .custom-header {
        .ion-toolbar {
          --min-height: 100px;
        }
      }

      .dash-items {
        .speedo {
          width: 50% !important;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .dashboard-cover {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }

      .status-bar {
        .order-detail {
          flex: 1 !important;
          width: auto !important;
          max-width: 100% !important;
        }

        .more-link {
          flex: 0 0 100px !important;
          width: 100px !important;
          max-width: 100px !important;
          cursor: pointer;
        }
      }
    }

    &.login-page-web {
      background: transparent;

      ion-img {
        width: 500px;
        margin-left: auto;
        margin-right: auto;
      }

      .bg-screen {
        width: 500px;
        margin-left: auto;
        margin-right: auto;
      }

      .additional-options {
        .custom-btn {
          .icon {
            width: 100px !important;
          }
        }
      }
    }

    &.on-board-page-web {
      background: transparent;

      ion-img {
        width: 500px;
        margin-left: auto;
        margin-right: auto;
      }

      .button-section {
        width: 500px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.signup-account-page-web,
    &.signup-business-page-web {
      background: transparent;

      ion-img {
        width: 500px;
        margin-left: auto;
        margin-right: auto;
      }

      .button-section {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }

      .set-account-section {
        width: 500px;
        margin-left: auto;
        margin-right: auto;
      }

      .signup-account {
        width: 500px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.order-page-web {

      .option-selector,
      .search-bar {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &.insights-page-web {
      .cards-area {
        &.insights-inner {
          margin-top: 0;
        }
      }
    }

    &.campaign-ads-list-page-web,
    &.campaign-template-page-web,
    &.notifications-page-web,
    &.statistics-page-web,
    &.marketing-page-web,
    &.insights-page-web,
    &.home-page-web,
    &.book-meeting-page-web,
    &.coversions-page-web,
    &.dashboard-page-web,
    &.my-store-page-web,
    &.my-website-page-web,
    &.ad-publish-status-page-web {
      position: relative;

      .blank-message-div {
        height: 300px;
        padding: 20%;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    &.dashboard-page-web {
      padding: 0;
      .blank-message-div {
        top: 15px;
      }
    }

    &.settings-page-web {
      .cards-area {
        .setting-single-group {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    &.profile-page-web {
      .profile-block {
        .profile-inner-block {
          background-color: transparent;

          .profile-image {
            width: 200px !important;
          }
        }
      }

      .profile-page-button-section {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &.change-password-web {

      .change-password-form-area,
      .button-section {
        width: 500px;
        margin: 0 auto;
      }

      .fields-section .error {
        top: 0;
      }
    }

    &.ad-wizard-page-web {
      .scenario-section {
        h2 {
          margin-left: 0;
          margin-right: 0;
        }

        .create-card {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 10px;
        }
      }

      .medium-section {
        .medium-card {
          .custom-check {
            ion-item {
              --background: var(--home-status-bar) !important;
              --background-hover: var(--home-status-bar) !important;
              --background-activated: var(--home-status-bar) !important;
              --ripple-color: var(--home-status-bar);
            }
          }
        }
      }

      .medium-first-section {

        .medium-section,
        h2,
        h3,
        .select-goal {
          margin-left: 0;
          margin-right: 0;
        }

        .questions-section,
        .create-campign-blocks {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .questions-section {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

    }

    &.add-key-words-page-web {
      .fields-section {
        .error {
          top: 0;
        }
      }
    }

    &.ad-publish-status-page-web {

      .ad-status-card,
      .process-info {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &.my-store-page-web {
      padding: 0;

      .book-meeting-cover-web {
        padding-bottom: calc(var(--base-padding) * 2);

        .book-meeting-banner {
          margin-bottom: 0;
        }
      }
    }
    
    &.marketing-page-web {
      padding: 0;
    }
  }

  .web-bg-grey {
    --background: var(--main-background-grey);
  }

  .inner-web-header {
    display: block;
  }

  ion-content {
    --background: var(--web-content-background);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &.bg-pink {
      --background: var(--web-content-background) !important;
    }

    &.bg-white {
      --background: var(--web-content-background) !important;
    }
  }

  &.left-menu {
    .menu-section {
      .menu-header {
        flex-direction: column;
        min-height: 150px !important;
        align-items: flex-start !important;

        .close-icon-container {
          display: flex;
          justify-content: flex-end;
          width: 100%;
        }

        .close {
          padding: 20px 25px;
          cursor: pointer;
        }
      }
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  ion-modal {
    ion-content {
      --background: var(--page-bg-grey) !important;
    }
  }

  .signup-business-page-web {
    .fields-section {
      ion-list {
        background: var(--onboard-background) !important;
      }
    }
  }

  .seo-card,
  .create-card {
    cursor: pointer;

    ion-card-content {
      height: 100%;
    }

    .seo-icon-area,
    .create-icon-area {
      padding: 5px;
    }
  }

  .close,
  .close-icon {
    svg {
      cursor: pointer;
    }
  }

  .custom-alert {
    --min-width: 500px;

    .alert-wrapper {
      border-radius: 20px;
    }

    .alert-message {
      background-color: var(--grey-bg);
      padding: 0;

      p {
        margin-top: 15px;
        margin-bottom: 0;
      }
    }

    &.message-text-on {
      .alert-message {
        text-align: center;
        padding: calc(var(--base-padding) * 2);
      }
    }

    .alert-button-group {
      background-color: var(--grey-bg);
      padding: calc(var(--base-padding) * 2);
      justify-content: space-between;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;

      .alert-button {
        width: calc(50% - var(--base-padding));
        min-width: 45% !important;
        background-color: var(--home-status-bar);
        border-radius: 15px;
        height: 52px;
        font-size: 16px;
        color: $text-color;
        flex: none;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: var(--home-status-bar);
        }

        .alert-button-inner {
          justify-content: center;
        }
      }
    }

    .alert-radio-group {
      background-color: var(--grey-bg);
      padding: calc(var(--base-padding) * 2);
      padding-bottom: 0;
      border-top: none;

      .alert-radio-button {
        width: 100%;
        background-color: var(--home-status-bar);
        margin-bottom: 10px;
        border-radius: 15px;
        margin-left: auto;
        margin-right: auto;
        height: 52px;
        font-size: 16px;
        color: $text-color;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: var(--home-status-bar);
        }

        .alert-button-inner {
          justify-content: center;
        }
      }
    }

    .alert-head {
      min-height: 60px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background-color: var(--home-status-bar);

      h2 {
        font-weight: 600;
        font-size: 19px;
        color: $text-color;
        margin-bottom: 5px;
      }
    }
  }



  .custom-action-sheet {
    .action-sheet-container {
      justify-content: center;
    }

    .action-sheet-group {
      --button-background-hover: var(--home-status-bar);

      &:first-child {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        .action-sheet-button {
          margin-top: 10px;
          border-radius: 0;
        }
      }

      &.action-sheet-group-cancel {
        .action-sheet-button {
          margin-bottom: 20px;
        }
      }
    }

    .action-sheet-group-cancel {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }

    .action-sheet-title {
      min-height: 60px;
      font-weight: 600;
      font-size: 19px;
      margin-bottom: 5px;
      text-align: center;
    }

    .action-sheet-button {
      width: calc(100% - 50px);
      margin-bottom: 10px;
      border-radius: 15px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  ion-modal {
    &.no-border-radius {
      --border-radius: 20px !important;
    }
  }

  .cards-area {
    ion-card {
      ion-card-content {
        .blurred-image-section {
          background-image: url(../assets/images/graph-block-web.webp) !important;

          &.highlights-section-blurred {
            background-image: url(../assets/images/graph-block-web-big.webp) !important;
            height: 450px !important;
          }

          &.keywords-section-blurred {
            background-image: url(../assets/images/keywords-block-web.webp) !important;
          }
        }
      }
    }
  }

  .order-card {
    .checkbox-sec {
      ion-item {
        --background-hover: var(--home-status-bar);
      }
    }
  }

  .info-icon-section {
    cursor: pointer;
  }

  .custom-action-sheet {
    .action-sheet-wrapper {
      --height: 100%;
    }
  }

  .date-time-popover {
    --width: auto !important;
  }

  .header-team-name {
    margin-left: 20px;
    font-size: 13px;
  }

  .preview-card-main {
    ion-card-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .auto-accept-terms {
    a {
      cursor: pointer;
    }
  }

  .contact-icon-cover {
    ion-button {
      --background-hover: transparent;
      --background-activated: var(--home-status-bar);
      --ripple-color: var(--home-status-bar);
    }
  }

  .blue-banner {
    cursor: pointer;
  }

  .marketing-page-web {
    // commented the code below for improving the error message visibility
    // min-height: auto !important;

    .create-campaign-section {
      .create-content-text {
        padding-bottom: 50px !important;

        .sub-title,
        .main-title {
          text-align: center;
        }

        .action-button-section {
          width: 60%;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .create-camapign-banner {
        aspect-ratio: 2.4;
      }
    }
  }

  ion-modal {

    &.dashboard-modal,
    &.dashboard-modal-video {
      --width: 400px;

      ion-content {
        --background: var(--ion-color-primary) !important;
      }
    }
  }

  .medium-section {
    .medium-card {
      ion-card-header {
        .medium-header {
          .medium-caption {
            .brand-caption {
              .caption-type {
                max-width: 100% !important;
                white-space: wrap !important;
              }
            }
          }
        }
      }
    }
  }
}