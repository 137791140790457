/*
* Dark Colors
* -------------------------------------------
*/
@use "../theme/variables.scss" as *;
body.dark {
  --ion-color-primary: #FF9999;
  --ion-color-primary-rgb: 247, 92, 148;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/*
* iOS Dark Theme
* -------------------------------------------
*/

.ios body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #000000;
}

body.dark {
  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }
}

/*
* Material Design Dark Theme
* -------------------------------------------
*/

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #000000;
}

// custom css for dark mode
body.dark {
  /** Custom colours **/
  --home-status-bar: #000000;
  --highlights-dash-items: #585858;
  --highlights-dash-items-border: #2a2b2f;
  --small-button-background: #585858;
  --small-button-border: #000000;
  --small-button-text: #ffffff;
  --card-bg-grey: #000000;
  --dashboard-bg: #575757;
  --main-background-grey: #404040;
  --modal-bg: #000000;
  --tab-bar-color: #000000;
  --black-border: #000000;
  --inner-small-text-color: var(--ion-color-step-600);
  --checkbox-border: var(--ion-color-step-800);
  --grey-bg: var(--main-background-grey);
  --page-bg-grey: var(--main-background-grey);
  --onboard-background: var(--main-background-grey);
  --search-border-color: #000000;
  --messages-border: var(--search-border-color);
  --messages-header: var(--home-status-bar);
  --customize-page-border: var(--main-background-grey);
  --sub-header-bg: #2A2B2F;
  --tech-name: #ffffff;
  --connections-bg: var(--dashboard-bg);
  --offers-block-bg: var(--home-status-bar);
  --webshop-banner: var(--home-status-bar);
  --seo-icon-area: #f0f0f0;
  --add-key-words-label: var(--ion-color-step-600);
  --accord-main-bg: #2e2e2e;
  --left-menu-accordion-border: var(--search-border-color);
  --offers-card-bg: var(--ion-card-background);
  --brand-caption-text: #000000;
  --dashboard-background: #404040;
  --dashboard-border: #000000;
  --web-content-background: #2B2B2F;
  --sources-border: var(--web-content-background);
  --timeline-bg: var(--tab-bar-color);
  --field-group-background: var(--card-bg-grey);
  --page-bg-grey-rgba: rgba(64, 64, 64, 1);
  --black-border: var(--small-button-text);
  --grey-border-marketing: var(--main-background-grey);
  --disable-text: var(--ion-color-step-650);

  .grey-bg {
    --background: var(--main-background-grey) !important;
    &.bg-pink {
      --background: var(--ion-color-primary) !important;
    }
    &.bg-white {
      --background: var(--card-bg-grey) !important;
    }
  }

  .custom-header ion-toolbar {
    --background: var(--home-status-bar);
  }

  .ion-page {
    .custom-main-tab {
      background-color: var(--main-background-grey) !important;

      ion-tab-bar {
        background-color: var(--tab-bar-color) !important;

        ion-tab-button {
          background-color: var(--tab-bar-color) !important;
        }
      }
    }
  }

  .custom-icon-image {
    .custom-icon-image-path {
      stroke: $white-bg !important;
      fill: $white-bg !important;
    }
  }

  .track-permission-svg {
      stroke: $white-bg !important;
  }

  .cards-area-main-outer {
    .cards-area-inner {
      .overlay-create-content {
        background-color: rgba(0, 0, 0, 0.4) !important;
      }
    }
  }

  .overlay-create-content {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .offer-list-section {
    .single-offer {
      background-color: var(--home-status-bar) !important;
      border-color: $black !important;

      .top-content {
        background-color: var(--dashboard-bg) !important;

        &::after {
          background: url(../assets/svg/circle-dark.svg) space !important;
        }

        .inner-content {
          .offer-text {
            color: $text-color !important;
          }
        }
      }

      .bottom-content {
        .claim-offer {
          color: var(--ion-color-medium-shade);
        }
      }
    }
  }

  .add-ecommerce-section {
    background-color: var(--main-background-grey) !important;

    .item {
      --background: var(--main-background-grey) !important;

      ion-input {
        border-color: var(--content-black) !important;
        background-color: var(--home-status-bar) !important;
      }
    }

    ion-button.custom-button {
      --background: rgba(255, 153, 153, 0.8);
      --background-hover: var(--button-hover-primary);
      --background-activated: var(--button-hover-primary);
      --background-focused: var(--button-hover-primary);
    }
  }

  .add-web-shops-page {
    --ion-background-color: var(--main-background-grey);

    .add-webshop-section {
      .item {
        --background: var(--main-background-grey);

        ion-input {
          border-color: var(--content-black) !important;
          background-color: var(--home-status-bar) !important;
        }
      }
    }
  }

  .web-shops-mail-sent {
    --ion-background-color: var(--main-background-grey);

    .pop-header {
      background-color: var(--main-background-grey);
    }

    .mail-sent-webshop {
      .inner-main-content {
        .mail-sent-message {
          p {
            color: var(--ion-color-step-600);
          }
        }
      }
    }
  }

  .connection-block {
    background-color: var(--dashboard-bg);
  }

  .connect-social-main {
    --ion-background-color: var(--main-background-grey);
  }

  .messages-page {
    --background: var(--main-background-grey);

    .pop-header {
      background-color: var(--home-status-bar);
    }

    .message-listing-area {
      background-color: var(--main-background-grey);

      .message-list {
        ion-item {
          --background: var(--main-background-grey);
          background: var(--main-background-grey);
        }
      }

      .message-sort {
        background: var(--main-background-grey);
        border-color: $black;
      }
    }

    .add-message-bar {
      background-color: $black;
    }
  }

  .cards-area {
    ion-card {
      ion-card-content {
        .blurred-image-section {
          background-image: url(../assets/images/graph-block-dark.png) !important;

          .join-message {

            h3,
            p {
              color: $text-color !important;
            }
          }

          &.highlights-section-blurred {
            background-image: url(../assets/images/highlights-block-dark.png) !important;
            height: 130px;
          }

          &.keywords-section-blurred {
            background-image: url(../assets/images/keywords-block-dark.png) !important;
            height: 120px;
          }
        }
      }
    }
  }

  .cards-area-seo {
    ion-card-content {
      .content-section {
        .rt-btn-block {
          .custom-marketing-button {
            --background: var(--main-background-grey) !important;
            color: $text-color;
          }
        }
      }
    }
  }

  .acc-set-up {
    .main-inner-caption {
      margin-top: 20px;
    }

    h3 {
      margin-bottom: 30px;
    }
  }

  .notification-settings {
    background: var(--home-status-bar) !important;
  }

  .notificalion-list {
    ion-item {
      --background: var(--card-bg-grey) !important;
      --padding-start: 10px !important;
    }

    .item.read {
      --background: var(--dashboard-bg) !important;
      color: var(--ion-color-step-600) !important;

      .notification {
        color: var(--ion-color-step-600) !important;
      }
    }
  }

  .swiper {
    .swiper-pagination-bullet {
      background-color: #ffffff;

      &.swiper-pagination-bullet-active {
        background-color: var(--ion-color-primary);
      }
    }
  }

  .demo-mode-banner {
    --ion-text-color: var(--brand-caption-text);
  }

  .cards-area.content-width-fix{
    .blured-card-skeleton {
      background-color: rgba(0, 0, 0, 0.6) !important;
    }
  } 

  // Styling for icons
  svg {
    #NEW-CHANGES {
      #arrow-down {
        stroke: $white-bg;
      }
    }

    #Connect-Store {
      stroke: #fff;
    }

    .inner-lines {
      #Web {
        fill: var(--ion-color-medium-shade);
      }
    }

    #Home-Menu {
      fill: $white-bg;

      #main-path {
        fill: $white-bg;
      }
    }

    #delete {

      #Path_348,
      #Path_349,
      #Path_350 {
        fill: var(--ion-color-medium-shade);
      }
    }

    #external-link {

      #Path,
      #Path-2,
      #Path-3 {
        stroke: var(--ion-color-medium-shade);
      }
    }

    #arrow-left {
      #Path {
        stroke: $text-color;
      }
    }

    &#chevron-right {
      #Path {
        fill: #fff;
      }
    }

    &#Filter {

      #Rectangle,
      #Rectangle-2 {
        fill: #fff;
      }

      #Oval-2,
      #Oval {
        stroke: #fff;
      }
    }

    #close-small {

      #Path,
      #Path-2 {
        stroke: $text-color;
      }
    }

    .inner-lines {
      #Web {
        fill: var(--ion-color-medium-shade);
      }
    }

    #language-icon {
      #Path_361 {
        fill: var(--ion-color-medium-shade);
      }
    }

    #menu-switch,
    #switch,
    #new-account,
    #experts,
    #settings,
    #logout {
      path {
        stroke: #fff;
      }
    }

    #Source-icon {
      circle {
        stroke: #fff;
      }
    }
  }

  .main-table-layout .table-footer {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 35%) !important;
  }

  .connected-websites-section,
  .all-sources,
  .section-without-integration {
    ion-card {
      background-color: var(--card-bg-grey) !important;
    }
  }

  .section-without-integration {
    ion-card {
      ion-card-content {
        .caption-content-section {
          ion-button {
            --border-color: var(--small-button-text);
            --border-radius: 25px;
            --border-width: 1px;
            --border-style: solid;
          }
        }
      }
    }
  }

  .filter-cover {
    .type-filters {
      ion-button.selected {
        border-color: var(--black-border) !important;
      }
    }
  }

  .process-info{
    .process-right-information {
      h3,
      p {
        --ion-text-color: var(--brand-caption-text);
      }
    }
  } 

  // styles for web section
  .web .custom-header .title-cover .logo .app-icon-main .cls-1 {
    fill: #fff;
  }

  .web {
    .custom-header-web {
      background-color: var(--home-status-bar);

      ion-toolbar {
        --background: var(--home-status-bar);
      }
    }

    ion-content {
      --background: var(--web-content-background) !important;

      &.signup-acc-cover,
      &.account-set-up,
      &.on-board,
      &.login-screen,
      &.login-email {
        --background: var(--onboard-background) !important;
      }
      &.bg-pink,
      &.bg-white {
        --background: var(--web-content-background) !important;
      }
    }

    ion-modal {
      ion-content {
        --background: var(--grey-bg) !important;
      }
    }
  }

  .web-content {
    &.ad-wizard-page-web {
      .medium-section {
        .medium-card {
          .custom-check {
            ion-item {
              --background: var(--ion-card-background) !important;
            }
          }
        }
      }
    }

    &.home-page-web {
      .cards-area {
        ion-card {
          ion-card-content {
            .blurred-image-section {
              background-image: url(../assets/images/graph-block-dark-web.webp) !important;

              &.highlights-section-blurred {
                background-image: url(../assets/images/highlights-block-dark-web.webp) !important;
                height: 450px !important;
              }

              &.keywords-section-blurred {
                background-image: url(../assets/images/keywords-block-dark-web.webp) !important;
              }
            }
          }
        }
      }
    }
  }

  .seo-bg {
    background: var(--home-status-bar);
  }

  .custom-white-border-button {
    --border-color: var(--seo-icon-area) !important;
    --background: var(--home-status-bar) !important;
  }

  .custom-black-solid-button {
    --border-color: var(--ion-color-light-contrast) !important;
    --background: var(--ion-color-light-contrast) !important;
    color: var(--brand-caption-text) !important;
  }

  ion-modal {

    &.dashboard-modal,
    &.dashboard-modal-video {
      ion-content {
        --background: var(--ion-color-primary) !important;
        --ion-text-color: var(--brand-caption-text);
      }
    }
  }
}

.fields-section {
  ion-list {
    background: var(--onboard-background);
  }
}

ion-footer {
  ion-toolbar {
    .footer {
      .next-button {
        ion-button.next-btn-ad-wizard {
          --ion-text-color: var(--card-bg-grey);
        }
      }
    }
  }
}

.store-background-fix {
  .connect-source-btn-area {
    ion-button {
      &.custom-btn-connect-source {
        --ion-text-color: var(--black-border);
      }
    }
  }
} 

.custom-action-sheet .action-sheet-button {
  --button-color-activated: var(--ion-text-color);
}

.campaign-list {
  .card-info h3 {
    color: var(--ion-text-color);
  }
}

