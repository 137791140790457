// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #FF9999;
  --ion-color-primary-rgb: 255, 153, 153;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #f3697c;
  --ion-color-primary-tint: #f9c1c9;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Custom colours **/
  --home-status-bar: #ffffff;
  --highlights-dash-items: #f9f9f9;
  --highlights-dash-items-border: #e8eaeb;
  --content-black: #000000;
  --content-white: #ffffff;
  --inner-small-text-color: #585858;
  --checkbox-border: #aaaaaa;
  --grey-bg: #f6f6f6;
  --onboard-background: #ffffff;
  --small-button-border: #aaaaaa;
  --small-button-text: #aaaaaa;
  --search-border-color: #d5d9dc;
  --messages-border: #242525;
  --messages-header: var(--content-black);
  --customize-page-border: var(--grey-bg);
  --sub-header-bg: #404040;
  --small-button-background: #ffffff;
  --tech-name: rgba(255, 255, 255, 0.2);
  --connections-bg: #dadada;
  --offers-block-bg: #f1f1f1;
  --webshop-banner: #1f206a;
  --seo-icon-area: #ffffff;
  --offers-top-content: #ffffff;
  --add-key-words-label:#000000;
  --left-menu-accordion-border: #FFFFFF;
  --accord-main-bg:#F7F7F7;
  --offers-card-bg: #ffffff;
  --brand-caption-text: #000000;
  --button-hover-primary: rgba(255, 153, 153, 1);
  --web-header: #0f0f0f;
  --btn-grey-bg: #ededed;
  --upload-btn-text: var(--content-black);
  --dashboard-background: var(--content-black);
  --dashboard-border: #3F3F3F;
  --web-content-background: #2B2B2F;
  --user-menu-border: #D9D9D9;
  --page-bg-grey: #EBEDF1;
  --page-bg-grey-rgba: rgba(235,237,241,1);
  --link-blue: #2550EC;
  --link-low: #EE4C4C;
  --table-border: #e0e0e0;
  --grey-border-up: #e5e5e5;
  --progress-blue: #BAA7F2;
  --progress-yellow: #E8D676;
  --sources-border: rgba(0, 0, 0, .1);
  --connected-green: #58AD43;
  --connect-green: #38B324;
  --not-connected: #898989;
  --timeline-bg: var(--content-white);
  --field-group-background: var(--onboard-background);
  --content-white-rgba: rgba(255,255,255,0);
  --black-border: var(--content-black);
  --grey-border-marketing: var(--table-border);
  --text-highlight-green: #4caf50;
  --text-highlight-red: #e91e63;
  --base-padding: 8px;
  --base-margin: var(--base-padding);
  --compact-base-spacing: calc(var(--base-padding) / 4);
  --disable-text: #555555;
  --error-info: #e74b4c;
}

// custom variables
body {
  --ion-font-family: "Poppins", sans-serif !important;
  --ion-color-primary: #FF9999;
  --ion-text-color: #000000;
  font-feature-settings: "lnum" 1;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Thin.ttf");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-ThinItalic.ttf");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-ExtraLight.ttf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-ExtraLightItalic.ttf");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-LightItalic.ttf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Italic.ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-SemiBoldItalic.ttf");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}

// Background
$page-background-light: #ffffff;
$white-bg: var(--content-white);
$black: var(--content-black);
$form-bg: #c9dddc;
$primary-pink: var(--ion-color-primary);

// Typography

$text-color: var(--ion-text-color);
$white-text: #fff;
$grey-text: #333;
$dark_grey: #666;
$light-grey: #ccc;
$secondary_grey: #e4e7ea;
$black-text: #000000;
$dark-text: #243847;
$menu-link: #1a4e72;
$match-day: #7d2fcb;
$match-day-dark: #233746;
$green-text: #6ce035;
$dark_pink: var(--ion-color-primary);

// Border
$border-light: #eceaea;
$danger: #ed2b2f;
$border-dark: #999999;
$table-border: #f8f8f8;
$active-border: #73cebf;
$progress-border: #d9d9d9;
$primary-border: rgba(31, 83, 80, 0.1);

//social-backgrounds
$fb_blue: #3c5899;
$gplus: #de4b39;
$twitter: #55acee;
$insta: #3f729b;

//status-colors
$in-progress: #147cc2;
$on-hold: #efb200;
$rejected: #f4645b;
$executed: #9eb23c;

// Transition
%transition {
  transition: all 0.5s ease-out;
}

%svg_icon {
  stroke: #ffffff;
  stroke-width: 3;
  stroke-miterlimit: 10;
}

%rating_section_colour {
  color: #f7d912;
}

%align {
  display: flex;
  align-items: center;
  justify-content: center;
}

//shadow
%shadow {
  -webkit-box-shadow: 0px 7px 16px -1px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 7px 16px -1px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 7px 16px -1px rgba(0, 0, 0, 0.35);
}

%shadow_dash {
  -webkit-box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.02);
  -moz-box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.02);
  box-shadow: 0px 2px 10px 5px rgba(0, 0, 0, 0.02);
}

%btn-shadow {
  box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.4);
}

%truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

$connect-light-blue: url(../assets/svg/connected-card-bg-blue.png);
$connect-orange: url(../assets/svg/connected-card-bg-orange.png);
$connect-green: url(../assets/svg/connected-card-bg-green.png);
$connect-dark-blue: url(../assets/svg/connected-card-bg-dblue.png);

$card-bg:
  "light-blue" #0c86ff $connect-light-blue,
  "orange" #f7a744 $connect-orange,
  "green" #0ac05c $connect-green,
  "dark-blue" #173a64 $connect-dark-blue;

$message-pinterest: url(../assets/svg/message-pinterest.svg);
$message-facebook: url(../assets/svg/message-fb.svg);
$message-twitter: url(../assets/svg/message-twitter.svg);
$message-instagram: url(../assets/svg/message-instagram.svg);

$message:
  "instagram" rgba(159, 55, 159, 0.15) rgba(159, 55, 159, 0.5) $message-instagram,
  "facebook" rgba(12, 71, 255, 0.15) rgba(12, 71, 255, 0.5) $message-facebook,
  "twitter" rgba(50, 152, 224, 0.15) rgba(50, 152, 224, 0.5) $message-twitter,
  "pinterest" rgba(247, 92, 148, 0.15) rgba(247, 92, 148, 0.5) $message-pinterest;

$publish:
  "fb" rgba(12, 71, 255, 1),
  "twitter" rgba(50, 152, 224, 1),
  "insta" rgba(159, 55, 159, 1),
  "pinterest" rgba(247, 92, 148, 1),
  "in" rgba(10, 102, 194, 1),
  "tiktok" rgba(246, 38, 105, 1);

$social:
	"apple",
	"facebook",
	"twitter",
	"google",
	"bankid";

$platforms:
	"google",
	"facebook",
	"instagram",
	"tiktok";
/*
* To enable dark theme, uncomment below line
*/
// @use "./dark.scss" as *;
