@use "../theme/variables.scss" as *;

ion-button {
  text-transform: inherit;
}

// Android notch space
.md .notch-space {
  padding-top: 20px;
}

// iOS notch space
.ios .notch-space {
  padding-top: 50px;
}

// Mobile web notch space
.plt-mobileweb {
  .ios .notch-space {
    padding-top: 35px;
  }
}

.pt-10 {
  padding-top: 10px;
}

.pt-5 {
  padding-top: 5px;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.ios .ios-padding-top {
  padding-top: 20px;
}

.error {
  font-size: 12px;
}

.ad-wizard-page-web {
  .swiper {
    .swiper-slide {
      align-items: normal !important;
    }
  }
}

.swiper {
  .swiper-pagination {
    bottom: 0;

    .swiper-pagination-bullet {
      height: 5px;
      width: 5px;
      background-color: #000000;

      &.swiper-pagination-bullet-active {
        width: 10px;
        height: 5px;
        border-radius: 2.5px;
        background-color: var(--ion-color-primary);
      }
    }
  }
}

.custom-btn {
  height: auto;

  button.button-native {
    height: 48px !important;
  }
}

.custom-header {
  --border: none;
  box-shadow: none;

  ion-toolbar {
    --background: #fff;
    --opacity: 1;
    --min-height: 50px;
    --border: none;
    margin-bottom: 5px;

    .menu-toggle.ios {
      padding-left: 8px;
    }

    ion-title {
      font-weight: 700;
      font-size: 17px;
      color: $text-color;
    }

    .week-selector,
    .additional-action-top {
      color: $text-color;
      --ion-toolbar-color: #14181c;
      font-size: 25px;

      ion-button {
        display: flex;
        align-items: center;
        font-size: 15px;
        --background: #fff;
        --ripple-color: #fff;

        .button-icon {
          width: 20px;
          padding-right: 5px;
          display: flex;
          @extend %align;

          app-cheveron-icon {
            height: 20px;
          }
        }
      }
    }

    .notification-icon-cover {
      padding-right: 8px;

      .notification-icon {
        position: relative;
        left: -4px;

        .badge {
          position: absolute;
          right: -8px;
          top: -3px;
          width: 19px;
          height: 19px;
          border-radius: 50%;
          background-color: #c52632;
          font-size: 9px;
          font-weight: 600;
          color: $white-text;
          line-height: 18px;
          text-align: center;
        }
      }
    }

    .contact-icon-cover {
      margin-right: 5%;
    }
  }
}

.custom-main-tab {
  ion-tab-bar {
    @extend %shadow;

    ion-tab-button {
      .tab-icon {
        width: 30px;
        height: 25px;
        @extend %align;

        svg {
          path {
            stroke: $text-color !important;
          }

          rect {
            stroke: $text-color !important;
          }
        }
      }

      &.tab-selected {
        position: relative;

        &:before {
          position: absolute;
          content: '';
          border-top: 2px solid var(--ion-color-primary);
          width: 80%;
          top: 0;
          transform: translateX(-50%);
          left: 50%;
        }

        .tab-icon {
          svg {
            path {
              stroke: var(--ion-color-primary) !important;
            }

            rect {
              stroke: var(--ion-color-primary) !important;
            }
          }
        }

        ion-label {
          color: #000000 !important;
        }
      }
    }
  }
}

.grey-bg {
  --background: var(--page-bg-grey);
}

.white-bg {
  background: $white-bg;
}

app-caret-icon {
  svg {
    #Triangle {
      fill: #0c47ff !important;
    }
  }
}

.position-down {
  app-caret-icon {
    svg {
      transform: rotate(180deg);

      #Triangle {
        fill: var(--text-highlight-red) !important;
      }
    }
  }
}

.green {
  app-caret-icon {
    svg {
      #Triangle {
        fill: var(--text-highlight-green) !important;
      }
    }
  }
}

.grey {
  app-caret-icon {
    svg {
      #Triangle {
        fill: #aaaaaa !important;
      }
    }
  }
}

.blank-message-div {
  padding: 0 calc(var(--base-padding) * 3);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  @extend %align;
  flex-direction: column;
  background-color: var(--page-bg-grey);
  z-index: 10;

  .logo-icon {
    width: 50%;
    height: auto;
  }

  h1 {
    font-weight: 700;
    font-size: 17px;
    color: $text-color;
  }

  p {
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    color: #aaaaaa;
    margin-top: 0;
  }
}

.store-right {
  app-cheveron-icon {
    svg {
      transform: rotate(270deg);
    }
  }
}

.store-connect-btn {
  .store-right {
    app-cheveron-icon {
      svg {
        transform: rotate(270deg);

        #Home-PRO {
          stroke: #aaaaaa !important;
        }
      }
    }
  }
}

.no-border-radius {
  --border-radius: unset !important;
}

ion-modal::part(backdrop) {
  --ion-backdrop-opacity: 1;
  --backdrop-opacity: var(--ion-backdrop-opacity, 1);
}

ion-modal {
  --border-radius: 20px;

  &.connection-provider-modal {
    .ion-page {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      ion-header {
        --min-height: 90px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        ion-toolbar {
          --min-height: 90px;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          padding-left: 20px;
          padding-right: 15px;
          position: relative;
          --border: none;
          --background: var(--home-status-bar);

          h2 {
            font-weight: 600;
            font-size: 19px;
            color: $text-color;
            margin-bottom: 5px;
            margin-top: 0;
          }

          p {
            font-size: 15px;
            font-weight: 200;
            color: var(--inner-small-text-color);
            margin-top: 0;
            margin-bottom: 0;
          }

          ion-buttons {
            position: absolute;
            top: 10px;
            right: -7px;
          }
        }
      }

      .tech-connected-display {
        @extend %align;
        padding-top: 35px;
        flex-flow: wrap;

        .single-block {
          min-width: 70px;
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 5px;
          margin-right: 5px;
          margin-bottom: 10px;

          .logo-block {
            width: 60px;
            height: 60px;
            @extend %align;
            background-color: $white-bg;
            border-radius: 18px;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.02);

            img {
              max-width: 30px;
              height: auto;
            }
          }

          .tech-label {
            font-size: 12px;
            text-transform: capitalize;
            font-weight: 700;
            color: $text-color;
            text-align: center;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.pop-header {
  padding: calc(var(--base-padding) * 2);
  background-color: $white-bg;
  font-style: normal;

  h2 {
    font-size: 28px;
    font-weight: 600;
    color: $text-color;
    margin-bottom: 5px;
    font-feature-settings: "liga" 0;
    margin-top: 0;
  }

  h3 {
    font-size: 19px;
    font-weight: 300;
    color: $text-color;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.blue-banner {
  background-color: $primary-pink;
  min-height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  @extend %align;

  ion-grid {
    padding: 0;

    ion-row {
      padding: 0;
      @extend %align;

      ion-col {
        padding: 0;
        display: flex;
        align-items: center;

        img {
          height: auto;
          max-width: 85%;
        }

        .banner-text {
          font-size: 13px;
          font-weight: 600;
          color: $text-color;
          border-left: 2px solid #f6f6f6;
          padding-left: 15px;

          span {
            color: $text-color;
          }
        }
      }
    }
  }
}

.store-connect {
  padding: 15px;

  h1 {
    font-weight: 300;
    color: $text-color;
    margin-bottom: 15px;
    font-size: 28px;
    margin-top: 0;
  }

  ion-item {
    &.store-connect-btn {
      height: 55px;
      --background: #ffffff;
      margin-bottom: 12px;
      --border-radius: 16px;

      ion-label {
        height: 55px;
        --background: #ffffff;
        margin: 0;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: $text-color;
      }

      .button-icon {
        width: 20px;
        padding-right: 5px;
        display: flex;
        @extend %align;

        app-cheveron-icon {
          height: 25px;
        }

        &.left-main-icon {
          width: 25px;
          padding-right: 0;
          margin-right: 15px;

          app-website-icon {
            height: 18px;
          }

          app-g-icon {
            height: 20px;
          }
        }
      }
    }
  }
}

.connected-websites-section {
  ion-card {
    ion-card-content {
      .more-options {
        app-options-icon {
          svg {
            transform: rotate(270deg);

            #Statistics---Advsertising {
              fill: $white-bg;
            }
          }
        }
      }
    }
  }
}

ion-header {
  font-family: var(--ion-font-family);

  .header-background {
    backdrop-filter: blur(0);
    display: none;
  }
}

.heading-bar {
  background-color: $white-bg;
  padding: 20px 15px;

  .close-icon {
    width: 18px;
    height: auto;
    margin-bottom: 17px;
    @extend %align;
  }

  h2 {
    font-size: 28px;
    font-weight: 700;
    color: $text-color;
    margin-bottom: 5px;
    margin-top: 0;
  }
}

.custom-action-sheet {
  .action-sheet-container {
    padding-left: 0;
    padding-right: 0;
  }

  .action-sheet-group {
    background-color: var(--home-status-bar);
    margin-bottom: 0 !important;
    border-radius: 0;

    &:first-child {
      border-radius: 20px 20px 0 0;
      margin-bottom: 0;
      padding-bottom: 50px;
    }

    &:last-child {
      .action-sheet-button {
        border-radius: 30px;
        border: 1px solid var(--black-border);
        width: calc(100% - 50px);
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;

        .action-sheet-button-inner {
          justify-content: center;
        }
      }
    }
  }

  .action-sheet-title {
    min-height: 60px;
    font-weight: 600;
    font-size: 19px;
    color: $text-color;
    margin-bottom: 0;
    background-color: var(--home-status-bar);
    text-align: center;
  }

  .action-sheet-button {
    --button-background-activated: var(--home-status-bar);
    width: 100%;
    background: var(--home-status-bar);
    color: $text-color;
    font-size: 15px;
    font-weight: 400;
    min-height: 45px;
    border-bottom: 1px solid var(--grey-border-marketing);
    --button-color-activated: var(--ion-text-color);
    --button-background-selected: var(--home-status-bar);

    .action-sheet-button-inner {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &.selected, &.action-sheet-selected {
      font-weight: 600;
      background-image: url(../assets/svg/platform-selected.svg);
        background-repeat: no-repeat;
        background-position: center right 20px;
    }
  }
}

.custom-toast {
  --background: #ffffff;
  --border-radius: 0;
  --color: #000000;

  &::part(container) {
    border-left: 4px solid var(--ion-color-primary);
    border-radius: 0;
    @extend %shadow;
  }

  &.success {
    &::part(container) {
      border-left: 4px solid var(--ion-color-success);
    }
  }

  &.info {
    &::part(container) {
      border-left: 4px solid var(--ion-color-tertiary);
    }
  }

  &.error {
    &::part(container) {
      border-left: 4px solid var(--ion-color-danger);
    }
  }
}

.message-listing-area .message-list ion-list ion-item.read ion-label h3 .time-stamp svg path {
  fill: #aaaaaa;
}

.md {
  .left-menu {
    ion-menu {
      ion-content {
        --padding-start: 0 !important;
        --padding-end: 0 !important;
        --padding-top: 0 !important;
        --padding-bottom: 0 !important;
      }

      &.md {
        ion-list {
          &#menu-list {
            ion-list-header {
              font-size: 19px !important;
              font-weight: 400 !important;
            }
          }
        }
      }
    }

    .user-details {
      ion-note {
        margin-bottom: 0 !important;
      }
    }
  }

  .cards-area {
    ion-card {
      ion-card-header {
        ion-button {
          --box-shadow: none;
          text-transform: capitalize;

          ion-icon {
            top: 0 !important;
          }
        }

        h3 {
          font-weight: 600;
          font-size: 15px;
          margin: 0;
        }

        &.pink-border {
          h3 {
            border-left: 3px solid var(--ion-color-primary);
          }
        }
      }
    }
  }

  .custom-header {
    ion-toolbar {
      position: relative;

      ion-title {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding-left: 100px;
        padding-right: 100px;
      }
    }
  }
}

ion-alert {
  &.select-account-identifier .alert-wrapper {
    max-width: 75vw;
    width: 75vw;
  }
}

.infinite-loading-spinner ion-spinner,
.loading-spinner ion-spinner,
ion-refresher ion-spinner {
  color: var(--ion-color-primary) !important;
}

.web-shops {
  .close {
    svg {
      #Connect-Store {
        stroke: #fff;
      }
    }
  }
}

.domain-card-block {
  .domain-card {
    &.active {
      .domain-single {
        .domain-name-block {
          .connection-icon-inner {
            svg {
              circle {
                fill: #14181c;
              }

              path {
                stroke: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}

.ads-banner-pink {
  .main-text-section {
    .rt-icon {
      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }
}

.domain-not-available {
  padding-left: 10px;
  padding-right: 10px;

  svg {
    width: 50px;
    height: auto;

    #Marketing {
      fill: #e5e6e9;
    }
  }
}

.title-team-name {
  font-size: 12px;
  font-weight: 600;
}

.server-message-container {
  background: var(--home-status-bar);
  padding: 15px;
  border-top: 1px solid #f6f6f6;
}

.server-message {
  border-left: 3px solid var(--ion-color-primary);
  padding-left: 10px;
  font-weight: 600;
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 0;
  color: $text-color;
}

.visitor-detail {
  flex: 0 0 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  border-right: none !important;

  &:last-child {
    padding-left: 0 !important;
  }
}

.information-popup {
  --height: auto;
  --border-radius: 8px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  padding: 20px;

  &::part(backdrop) {
    --ion-backdrop-opacity: 0.5;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.5);
  }

  ion-modal ion-toolbar {
    --background: rgb(14 116 144);
    --color: white;
  }

  ion-modal {
    --border-radius: 20px;
  }
}

ion-popover.custom-popover,
ion-popover.custom-popover+ion-popover:not(.custom-popover) {
  top: 0px !important;

  &:before {
    background-image: none;
  }
}

ion-popover {
  top: 215px !important;
  border: none;

  &.ios:before {
    top: -100px;
  }

  &:before {
    content: "";
    width: 120px;
    height: 200px;
    background-image: url(../assets/svg/arrow.svg);
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: -160px;
    right: 36px;
  }

  &::part(arrow) {
    display: none !important;
  }

  ion-card-header {
    height: 50px;

    ion-card-title {
      font-size: 20px;
    }
  }

  ion-card-content {
    padding-left: 16px !important;
    padding-right: 16px !important;
    font-size: 15px !important;
  }

  .footer {
    display: none !important;
  }
}

.terms-and-conditions {
  .content {
    p {
      line-height: 25px;
      padding: 15px;
      margin: 0;
      font-size: 14px;
    }
  }
}

.seo-card,
.create-card,
.medium-first-section {
  .info-icon-section {
    svg {
      width: 20px;
    }
  }
}

.menu-items {
  .item {
    .menu-icon {
      margin-right: 20px;
      width: 30px;
      display: flex;
      justify-content: center;
      margin-top: 5px;

      svg {
        width: 24px;
        height: auto;
      }
    }

    ion-label {
      font-size: 16px;
      font-weight: 400;
      color: var(--ion-text-color);
    }
  }
}

.profile-block {
  .profile-inner-block {
    .profile-image {
      .edit-button {
        svg {
          width: 12px;
          height: auto;
        }
      }
    }
  }
}

.setting-single-group {
  .single-settings-button {
    .list-icon {
      svg {
        #language-icon {
          #Path_361 {
            fill: #898b8d;
          }
        }
      }
    }
  }
}

.accordion-gp {
  ion-accordion-group {
    ion-accordion {
      .ion-accordion-toggle-icon {
        font-size: 20px;
        color: var(--inner-small-text-color);
      }
    }
  }
}

.legal-information {
  font-size: 10px;
  border: 1px dashed #d5d9dc;
  border-radius: 10px;
  padding: 10px;
  font-weight: 400;

  span {
    color: $primary-pink;
  }
}

.limit-mobile-modal {
  --border-radius: none;

  .modal-content {
    padding: 10px 30px;
    @extend %align;
    flex-direction: column;
    background: var(--page-bg-grey);
    z-index: 10;
    height: 100vh;

    .logo-icon {
      width: 30%;
      height: auto;
    }

    p {
      text-align: center;
      font-size: 13px;
      font-weight: 400;
      color: var(--ion-text-color);
      margin: 25px 0px 25px 0px;
    }

    ion-icon {
      width: 200px;
      height: 50px;
    }

    img {
      width: 175px;
    }
  }
}

.process-info {
  .process-info-icon {
    svg {
      path {
        fill: var(--content-white);
      }
    }
  }
}

@media (max-width: 375px) {
  .dashboard {
    .single-item-top {
      .value {
        font-size: 14px !important;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

body.dark {
  .server-message-container {
    border-top: 1px solid $black;
  }

  .custom-main-tab ion-tab-bar ion-tab-button.tab-selected ion-label {
    color: $text-color !important;
  }

  .toolbar-title-default {
    ion-buttons {
      ion-menu-button {
        app-svg {
          svg {
            #Menu {
              fill: $white-bg;
            }
          }
        }
      }

      &.contact-icon-cover {
        ion-button {
          .contact-icon {
            #Path_5 {
              fill: $white-bg;
            }
          }
        }
      }

      &.notification-icon-cover {
        ion-button {
          .notification-icon {
            #Fill-1 {
              fill: $white-bg;
            }
          }
        }
      }
    }
  }

  .pop-header {
    --content-white: var(--home-status-bar);
  }

  .heading-bar {
    background-color: var(--home-status-bar);
  }

  .blank-message-div {
    background-color: var(--main-background-grey);
  }

  .background-fix-dark {
    --background: var(--main-background-grey);
  }

  .stats-main {
    .cards-area {
      ion-card {
        ion-card-header {
          .store-detail {
            h3 {
              background: url(../assets/svg/oval-bright.svg) left center no-repeat !important;
            }
          }
        }

        ion-card-content {
          &.visitors-list {
            .visitor-detail h3 {
              background: url(../assets/svg/oval-bright.svg) left center no-repeat !important;
            }
          }
        }
      }
    }
  }

  // SEO page nth child background
  .main-table-layout {
    .seo-row:nth-of-type(odd) {
      background: $grey-text !important;
    }
  }
}

.intercom-lightweight-app {
  display: none !important;
}

.custom-header .title-cover .logo,
.inner-web-header {
  display: none;
}

.cards-area {
  ion-card {
    ion-card-header {
      flex-direction: row;
    }
  }
}

.fields-section {
  .error {
    position: relative !important;
    top: -15px;
  }
}

ion-fab {
  position: fixed;
}

.questions-section {
  .form-lable-sec {
    .form-cover-single {
      ion-list {
        ion-item {
          ion-input {
            display: flex;
            align-items: center;

            label {
              position: absolute;
              width: 100%;
              padding-right: 20px;
            }
          }
        }
      }
    }
  }
}

.app-logo .header-logo {
  max-width: 90% !important;
}

.go-next-icon {
  svg {
    width: 10px;
    height: auto;
  }
}

.menu-bar-available {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  min-height: 130px;

  h2 {
    flex: 1;
  }

  .user-menu-section {
    width: 95px;
    border-radius: 25px;
    border: 1px solid var(--user-menu-border);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    height: 45px;

    .user-image {
      width: 35px;
      height: 35px;
      background-size: cover;
      background-position: center center;
    }

    .menu-icon {
        line-height: 4px;
        padding-left: 5px;
    }
  }
}

// Common styling for dynamic contents
.h-small {
  min-height: 15vh;
}

.h-medium {
  min-height: 20vh;
}

.h-large {
  min-height: 25vh;
}

.status-icon-main {
  &.connected {
    #not-connected {
      display: none;
    }
  }

  &.not-connected {
    color: var(--not-connected) !important;

    #not-connected {
      display: block;
    }

    #connected {
      display: none;
    }
  }
}

ion-footer {
  ion-toolbar {
    .footer {
      .next-button {
        .next-btn-ad-wizard {
          .next-icon-ad {
            svg {
              transform: rotate(180deg);

              path {
                stroke: var(--content-black);
              }
            }
          }
        }
      }
    }
  }
}

.graph-point-value {
  font-size: 12px;
}

.connected-websites-section {
  ion-card {
    .connected-btn-wrapper {
      .connected-btn {
        svg {
          #Fill-1 {
            fill: var(--connected-green);
          }
        }
      }
    }
  }
}

.dashboard-modal {
  ion-modal {
    --height: 50%;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }

  ion-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
  }

  ion-modal ion-toolbar {
    --background: rgb(14 116 144);
    --color: white;
  }
}

ion-modal {

  &.dashboard-modal,
  &.dashboard-modal-video {
    --height: 400px;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --width: 85%;

    ion-content {
      --background: var(--ion-color-primary);
      position: relative;
      --padding-start: 30px;
      --padding-end: 30px;
      --padding-top: 30px;
      --padding-bottom: 30px;

      .rt-minimise-button {
        position: absolute;
        right: 15px;
        top: 15px;
        width: 70px;
        text-align: center;
        font-size: 11px;
        font-weight: 300;
        color: var(--ion-text-color);
        line-height: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        ion-icon {
          font-size: 20px;
        }
      }

      .main-content {
        padding-top: var(--base-padding);

        h2 {
          font-size: 22px;
          font-weight: 400;
          color: var(--ion-text-color);
          width: 50%;
          margin-top: 0;

          span {
            font-weight: 600;
          }
        }

        .buttons-area {
          margin-top: 30px;
          margin-bottom: 30px;

          ion-button {
            &.custom-button {
              min-height: 50px;
              --background-hover: var(--content-black);
              --background-activated: var(--content-black);
              --background-focused: var(--content-black);
              --background: var(--content-black);
              font-size: 16px;
              font-weight: 600;
              color: #fff;
              margin-bottom: 15px;

              &:last-child {
                margin-bottom: 0;
              }

              &.outline-button {
                --background-hover: transparent;
                --background-activated: transparent;
                --background-focused: transparent;
                --background: transparent;
                --border-color: var(--content-black);
                color: var(--ion-text-color);
              }
            }
          }
        }

        .notes-section {
          font-size: 14px;
          font-weight: 400;
          color: var(--ion-text-color);
        }
      }
    }
  }

  &.dashboard-modal-video {
    --height: 90%;

    ion-content {
      --padding-start: 15px;
      --padding-end: 15px;

      .main-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
          font-size: 20px;
          font-weight: 700;
          color: var(--ion-text-color);
          margin: 0 auto;
          text-align: center;
          width: 90%;
        }

        .video-cover {
          position: relative;
          height: 70vh;

          video {
            border-radius: 15px;
          }

          .button-cover {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .play-button {
              width: 120px;
              border-radius: 25px;
              height: 50px;
              border: 1px solid var(--content-white);
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              background-color: rgba(0, 0, 0, 0.2);

              .play-icon {
                font-size: 25px;
                color: var(--content-white);
                line-height: 15px;
              }

              .video-time {
                color: var(--offers-top-content);
                font-size: 14px;
                padding-left: 10px;
              }
            }
          }

        }


        .speedy-explanation {
          font-size: 16px;
          font-weight: 400;
          color: var(--ion-text-color);
          text-align: center;
          line-height: 20px;
          padding-top: 15px;
        }
      }
    }
  }

  &::part(backdrop) {
    background: rgba(0, 0, 0);
    --ion-backdrop-opacity: 0.8;
    opacity: 0.8;
  }
}

.login-screen {
  .bg-screen {
    .additional-options {
      .custom-btn {
        .icon {
          svg {
            display: none;
          }
        }

        @each $name in $social {
          &.#{$name} {
            .icon {
              svg {
                &##{$name} {
                  display: inline-flex;
                }
              }
            }

          }
        }
      }
    }
  }
}

.hide {
  display: none !important;
}

.no-bottom-margin {
  .demo-mode-banner {
    margin-bottom: 0px !important;
  }
}

.text-highlight-green {
  color: var(--text-highlight-green) !important;
}

.text-highlight-red {
  color: var(--text-highlight-red) !important;
}

.vertical-space-fix {
  padding-top: var(--base-padding);
  padding-bottom: var(--base-padding);
  &.my-store {
    padding-top: 0;
  }
}

// Campaigns Preview CSS
.campaigns-preview {
  margin: calc(var(--base-margin) * 2) !important;
}

.campaigns-preview.web-preview {
  margin: calc(var(--base-margin) * 2) auto !important;
}

.post {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
  margin: calc(var(--base-margin) * 2) auto;
  text-align: left;
  h3 {
    color: #000000;
    padding: var(--base-padding);
  }
  .post-header {
    padding: calc(var(--base-padding) * 2);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .post-header-container{ 
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
  .profile-picture {
    background-color: #BCC0C4;
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
  .page-name-sponsored-container {
    display: flex;
    flex-direction: column;
  }
  .page-name {
    font-weight: bold;
    color: #000000;
  }
  .sponsored {
    color: #65676B;
    font-size: 0.9em;
    display: flex;
    gap: 4px
  }
  .options {
    font-weight: bold;
    color: #65676B;
  }
  .caption {
    padding: 0 calc(var(--base-padding) * 2);
    margin-bottom: calc(var(--base-margin) * 2);
    color: #000000;
  }
  .image-placeholder {
    background-color: #F0F2F5;
    // height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #BCC0C4;
    font-size: 2em;
  }
  .website-info {
    padding: calc(var(--base-padding) * 2);
    border-bottom: 1px solid #E4E6EB;
    background-color: #F2F3F5;
  }
  .website-url {
    color: #65676B;
    font-size: 0.9em;
    text-transform: uppercase;
  }
  .headline {
    font-weight: bold;
    margin-top: calc(var(--base-margin) / 2);
    color:#000000;
  }
  .cta-button {
    background-color: #E4E6EB;
    color: #050505;
    border: none;
    padding: var(--base-padding) calc(var(--base-padding) * 2);
    border-radius: 6px;
    float: right;
    font-weight: bold;
    margin-top: -24px;
  }
  .engagement {
    display: flex;
    padding: calc(var(--base-padding) * 2);
    color: #65676B;
    font-size: 0.7em;
    justify-content: space-between;
  }
  .reactions {
    display: flex;
    align-items: center;
  }
  .reaction-icons {
    margin-right: calc(var(--base-margin) / 2);
  }
  .reaction-icons img {
    width: 16px;
  }
  .actions {
    display: flex;
    justify-content: space-around;
    padding: calc(var(--base-padding) * 2);
    border-top: 1px solid #E4E6EB;
  }
  .action {
    color: #65676B;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 4px
  }
  
  .action span {
    font-weight: 500;
  }
  .video-item{
    width: 100%;
  }
}

.instagram-post {
  background-color: white;
  border: 1px solid #DBDBDB;
  border-radius: 3px;
  width: 100%;
  color:#000000;
  margin-bottom: calc(var(--base-padding) * 2);
  text-align: left;

  .post-header {
    display: flex;
    align-items: center;
    padding: calc(var(--base-padding) * 2);
  }
  .profile-pic {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #EFEFEF;
    margin-right: var(--base-margin);
  }
  .user-sponso-container {
    display: flex;
    flex-direction: column;
  }
  .username {
    font-weight: 600;
    font-size: 14px;
  }
  .sponsored {
    color: #8E8E8E;
    font-size: 12px;
  }
  .options {
    margin-left: auto;
    font-weight: bold;
  }
  .image-placeholder {
    background-color: #EFEFEF;
    width: 100%;
  }
  .image-text {
    color: #8E8E8E;
    font-size: 14px;
    text-align: center;
  }
  .video-item {
    width: 100%;
  }
  .learn-more {
    padding: calc(var(--base-padding) * 2);
    border-top: 1px solid #EFEFEF;
  }
  .learn-more-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: #262626;
    text-decoration: none;
  }
  .arrow {
    height: 16px;
  }
  .engagement {
    padding: 0 calc(var(--base-padding) * 2) var(--base-padding);
  }
  .icons {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    margin-bottom: var(--base-margin);
  }
  .left-icons {
    display: flex;
    gap: 16px;
  }

  .heart, .comment, .send, .bookmark {
    width: 24px;
  }
  .likes {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: var(--base-margin);
  }
  .caption {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: calc(var(--base-margin) / 2);
  }
  .username-caption {
    font-weight: 600;
  }
}

.story-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000;
  color: #fff;
  margin-bottom: calc(var(--base-margin) * 2);
  min-height: 600px;
  .video-background, .image-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
  }
  .content {
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    min-height: 600px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .user-info {
    display: flex;
    align-items: center;
  }
  .profile-pic {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #fff;
    margin-right: var(--base-margin);
  }
  .username {
    font-weight: bold;
  }
  .sponsored {
    font-size: 0.8em;
    opacity: 0.7;
  }
  .close {
    font-size: 1.5em;
  }
  .aspect-ratio {
    text-align: center;
    font-size: 2em;
    font-weight: bold;
  }
  .recommendation {
    text-align: center;
    font-size: 0.8em;
    opacity: 0.7;
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-container{
    display: flex;
    flex-direction: column;
    min-height: 110px;
  }
  .learn-more {
    background-color: #fff;
    color: #000;
    padding: var(--base-padding) calc(var(--base-padding) * 2);
    border-radius: 20px;
    text-decoration: none;
    font-weight: bold;
  }
  .arrow-up {
    display: flex;
    justify-content: center;
  }
  .arrow-up, .arrow-right {
    font-size: 1.5em;
  }
  .arrow-right {
    position: absolute;
    right: 16px;
    bottom: 16px;
  }
}

.card-section-detail {
  position: relative;
  overflow: hidden;
}

.media-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-background {
  background-size: cover;
  background-position: center;
}

.video-background {
  object-fit: cover;
}

.card-info {
  position: relative;
  z-index: 1;
}

.button-choose {
  background-color: #ff9999;
  width: 100%;
  padding: calc(var(--base-padding) * 2);
  border-radius: 16px;
  color: #ffffff;
  font-size: 18px;
  margin-top: var(--base-margin);
}

.ad-publish-status-page-web app-checked-icon svg {
  height: 32px;
  width: 32px;
}

.store-page-web {
  app-checked-icon svg, app-failed-icon svg {
    height: 20px;
    width: 20px;
  }
}

.title-with-button:not(.menu-bar-available) {
  display: flex;
  align-items: center;

  .close {
    display: flex;
    align-items: center;

    app-back-icon {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  h2 {
    margin: 0 calc(var(--base-padding) * 2) !important;
  }
}

/*
  TIKTOK Preview styles
*/
.tiktok-container {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  padding: var(--base-padding);
  .container {
    max-width: 400px;
    margin: 0 auto;
    color: white;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70vh;
    position: relative;
    overflow: hidden;
  }
  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    // z-index: -1;
    &.fallback-img {
      height: 100vh;
    }
  }
  .content-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  }
  .header {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
  }
  .header-item {
    color: #9ca3af;
    cursor: pointer;
  }
  .header-item.active {
    color: white;
    border-bottom: 2px solid white;
  }
  .bottom-section {
    padding: 1rem;
  }
  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .left-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-right: 1rem;
    width: 100%;
  }
  .brand-name {
    font-weight: bold;
    font-size: 0.875rem;
    text-align: left;
  }
  .description {
    font-size: 0.875rem;
    margin: 0;
    text-align: left;
  }
  .sponsored-tag {
    background-color: #6b7280;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.625rem;
    display: inline-block;
    width: auto;
    align-self: flex-start;
  }
  .music-info {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  .music-icon {
    font-weight: bold;
  }
  .music-text {
    font-size: 0.75rem;
  }
  .learn-more-btn {
    background-color: black;
    color: white;
    border: none;
    padding: 0.5rem;
    border-radius: 0.25rem;
    margin-top: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
  }
  .right-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
  .profile-pic {
    width: 2.5rem;
    height: 2.5rem;
    background-color: #9ca3af;
    border-radius: 9999px;
    border: 1px solid white;
  }
  .action-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .action-count {
    font-size: 0.875rem;
  }
  /* Responsive adjustments */
  @media (max-width: 640px) {
    .container {
      height: 85vh;
      border-radius: 0;
    }
  }
}

/*
  GOOGLE Preview styles
*/
.google-ad-preview {
  min-width: 60%;
  border: 1px solid #ddd;
  padding: calc(var(--base-padding) * 2);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  background-color: #ffffff;
  text-align: left;
  
  .sponsored {
    color: #000000;
    font-size: 14px;
    margin-bottom: calc(var(--base-margin) / 2);
    font-weight: 600;
  }

  .url {
    display: flex;
    align-items: center;
    color: #1a0dab;
    font-size: 14px;
    text-decoration: none;

    img {
      font-size: 16px;
      margin-right: calc(var(--base-margin) / 2);
      background-color: #e3eeff;
      border-radius: 50%;
      padding: 4px;
    }
  }

  .title {
    color: #1a0dab;
    font-size: 16px;
    font-weight: bold;
    margin: var(--base-margin) 0;
    line-height: 1.2;
  }

  .description {
    color: #4d5156;
    font-size: 14px;
    line-height: 1.5;
  }
}

ion-app.web .no-margin {
  margin: 0;
}

body:not(.dark) {
  .bottom-highlight-image {
    filter: invert(1);
  }
}

.custom-btn-create {
    --background-hover: var(--content-white);
    --background-activated: var(--content-white);
    --background-focused: var(--content-white);
  }